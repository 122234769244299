@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?kcko1r') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?kcko1r') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?kcko1r##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-facebook-f {
  &:before {
    content: $icon-facebook-f; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.icon-arrow-thin-right {
  &:before {
    content: $icon-arrow-thin-right; 
  }
}
.icon-arrow-thin-left {
  &:before {
    content: $icon-arrow-thin-left; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}

