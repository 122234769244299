.italic-title {
  font-size: rem.convert(35px);
  font-style: italic;
  text-transform: uppercase;
  color: $gray-800;
  margin-bottom: rem.convert(60px);
  font-weight: $font-weight-bold;
  font-family: $font-family-secondary;
}

.italic-text {
  font-style: italic;
  font-weight: $font-weight-bold;
  font-family: $font-family-secondary;
  margin-bottom: rem.convert(35px);

  @include media-breakpoint-up(lg) {
    column-count: 2;
    column-gap: 2rem;
  }
}
