.project-details {
  h2 {
    color: $blue;
    font-family: $font-family-secondary;
    margin-top: rem.convert(40px);
    margin-bottom: rem.convert(46px);
  }

  .info-grid {
    list-style: none;
    padding: 0;
    margin: 0 0 rem.convert(90px);
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    font-size: rem.convert(19px);
    text-align: center;
    justify-content: center;
    border-top: 1px solid $blue-700;

    li {
      padding: rem.convert(30px) rem.convert(40px) rem.convert(20);
      border-bottom: 1px solid $blue-700;
      border-right: 1px solid $blue-700;
      border-left: 1px solid $blue-700;
      flex: 1;
      white-space: nowrap;
      min-width: 50%;

      @include media-breakpoint-up(sm) {
        min-width: math.div(100%, 3);
      }

      @include media-breakpoint-up(md) {
        min-width: 25%;
      }

      @include media-breakpoint-up(lg) {
        min-width: auto;
      }
    }

    h3 {
      font-size: inherit;
      color: $black;
      margin-bottom: rem.convert(30px);
    }

    p {
      color: $gray-600;
    }
  }

  .main-image {
    display: block;
    width: 100%;
    margin-bottom: rem.convert(35px);
  }

  .modal-body {
    padding: 0;

    img {
      display: block;
      width: 100%;
    }
  }

  .image-grid {
    list-style: none;
    padding: 0;
    margin: 0 0 rem.convert(96px);
    display: grid;
    gap: 4px 4px;
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: repeat(4, 1fr);
    }

    a {
      display: block;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .description {
    position: relative;

    &::after {
      content: '';
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      background-image: url('../images/logo-grey.png');
      background-size: cover;
      background-repeat: no-repeat;
      padding-bottom: 40%;
      background-position: center;
    }

    h3 {
      font-size: rem.convert(19px);
      color: $black;
      font-weight: $font-weight-bold;
      font-family: $font-family-secondary;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    p {
      color: $gray-600;
      font-family: $font-family-secondary;
      font-size: rem.convert(19px);
    }
  }
}

.related-projects {
  .italic-title {
    text-align: center;
    margin-bottom: rem.convert(95px);
  }

  .project-list-alt {
    list-style: none;
    padding: 0;
    margin: 0 0 rem.convert(195px);
    display: grid;
    gap: rem.convert(74px) rem.convert(36px);
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    a {
      text-decoration: none;

      &:hover {
        .link-button {
          background-color: $primary;
          color: $white;
          border-color: $primary;

          .icon {
            border-left-color: $white;
          }
        }
      }
    }

    img {
      display: block;
      width: 100%;
      height: rem.convert(276px);
      object-fit: cover;
      margin-bottom: 1rem;
    }

    .link-button {
      display: flex;
      border: 2px solid $gray-400;
      font-style: italic;
      font-weight: $font-weight-light;
      color: $gray-800;
      flex-flow: row nowrap;
      text-align: center;
      height: rem.convert(86px);
      align-items: center;
      font-size: rem.convert(18px);
      text-transform: uppercase;
      transition: all 200ms ease-out;

      span {
        flex: 1;
        padding: 0 1rem;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon {
        width: rem.convert(100px);
        border-left: inherit;
        line-height: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem.convert(30px);
        transition: inherit;
      }
    }
  }
}
