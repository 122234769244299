.navbar.navbar-main {
  z-index: $zindex-fixed;

  .navbar-brand {
    .subtitle {
      display: none;
      text-transform: uppercase;
      color: $blue-800;
      font-family: $font-family-secondary;
      white-space: nowrap;
      letter-spacing: 1px;

      @include media-breakpoint-up(md) {
        display: initial;
        margin-left: 1rem;
        font-size: 1rem;
      }

      @include media-breakpoint-up(xl) {
        font-size: 1.25rem;
      }
    }
  }

  .navbar-nav {
    margin-left: auto;
  }

  .nav-link {
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $blue-900;
    display: flex;
    align-items: center;

    &:hover {
      color: $primary;
    }

    .icon {
      margin-right: 1rem;
    }
  }

  .dropdown-menu {
    background-color: $gray-200;
    border-color: $gray-200;
    left: 2rem;

    .dropdown-item {
      text-transform: uppercase;
      color: $blue-900;

      &:hover {
        color: $primary;
      }
    }
  }

  .social-links {
    $margin: $navbar-padding-y * -1;
    margin: $margin 0 $margin 0;
    padding: 0 rem.convert(42px);
    list-style: none;
    align-self: stretch;
    display: none;
    flex-flow: row nowrap;
    align-items: center;
    background-color: $blue-800;

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    li {
      margin-right: rem.convert(24px);

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      background-color: $gray-200;
      text-decoration: none;
      color: $blue-800;
      width: rem.convert(40px);
      height: rem.convert(40px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: rem.convert(30px);
      border-radius: 100%;

      &:hover {
        background-color: $white;
      }
    }
  }
}
