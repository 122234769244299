@use 'sass:math';
@use '@sass-collective/rem' with (
  $baseline: 15px
);

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,500;1,300&family=Roboto:ital,wght@0,400;0,700;1,100;1,700&display=swap');
$icomoon-font-path: '../fonts/fonts';
@import '../fonts/style';

// Dependencies
// Bootstrap
@import 'bootstrap';
// Swiper
@import 'swiper/swiper';
@import 'swiper/components/effect-fade/effect-fade';

// Common
@import 'base';

// Components
@import 'components/navbar';
@import 'components/footer';
@import 'components/page-header';
@import 'components/vertical-divider';
@import 'components/italic-content';
@import 'components/whatsapp-floating-button';
@import 'components/project-gallery';

// Pages
@import 'pages/home';
@import 'pages/projetos';
@import 'pages/projeto';
@import 'pages/quem-somos';
@import 'pages/depoimentos';
@import 'pages/contato';
