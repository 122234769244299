.project-categories {
  list-style: none;
  padding: 0;
  margin: 0 0 1rem;
  display: flex;
  flex-flow: row wrap;

  li {
    margin: 0 rem.convert(25px) 1rem;
  }

  a {
    color: $gray-600;
    text-decoration: none;
    text-transform: uppercase;
    font-size: rem.convert(20px);
    font-family: $font-family-secondary;
    font-weight: 700;

    &:hover,
    &.active {
      color: $black;
    }
  }
}

.project-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: rem.convert(8px) rem.convert(8px);

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(4, 1fr);
  }

  li {
    display: flex;
    min-width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  a {
    position: relative;
    display: block;
    color: $white;
    text-decoration: none;
    display: flex;
    height: 100%;
    width: 100%;

    &:hover {
      h3 {
        opacity: 1;
      }
    }
  }

  h3 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    text-align: center;
    font-size: rem.convert(16px);
    transition: opacity 250ms ease-out;
    margin-bottom: 0;
    padding: 1rem;
    background-image: linear-gradient(to bottom, transparent, rgba($black, 0.8));
    text-transform: uppercase;
  }
}

.projects-contact {
  padding-bottom: rem.convert(240px);

  @include media-breakpoint-up(xxl) {
    background-image: url('../images/logo-grey.png');
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 50% auto;
  }

  .btn {
    border-color: $gray-400;
    font-style: italic;
    font-size: rem.convert(18px);
    text-transform: uppercase;
    font-weight: $font-weight-light;
    border-width: 2px;
    padding: rem.convert(30px) rem.convert(90px);
    line-height: 1;

    &:hover {
      background-color: $gray-400;
      color: $gray-800;
    }
  }
}
