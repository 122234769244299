.whatsapp-floating-button {
  $button-color: #2cff8a;
  position: fixed;
  bottom: 10%;
  right: 0;
  z-index: $zindex-fixed;
  background-color: $button-color;
  color: $blue-800;
  text-decoration: none;
  font-size: rem.convert(24px);
  padding: 1rem rem.convert(20px);
  line-height: 1;
  text-transform: uppercase;
  font-family: $font-family-secondary;
  font-weight: $font-weight-bold;

  &:hover {
    .icon {
      background-color: $link-hover-color;
    }
  }

  .icon {
    background-color: $blue-800;
    color: $button-color;
    border-radius: 50%;
    width: rem.convert(32px);
    height: rem.convert(32px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
