.testimonials {
  background-image: url('../images/logo-grey.png');
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;

  ul {
    max-width: rem.convert(790px) + ($spacer * 2);
    margin: 0 auto;
    padding: 0 $spacer;
    list-style: none;
  }

  li {
    margin-bottom: rem.convert(130px);
  }

  img {
    display: block;
    border-radius: 50%;
    width: 100%;
  }

  h3 {
    font-size: rem.convert(35px);
    font-family: $font-family-secondary;
    font-style: italic;
    font-weight: $font-weight-bold;
    margin: rem.convert(20px) 0 rem.convert(50px);
  }

  p {
    font-style: italic;
    font-weight: $font-weight-light;
  }
}
