.page-header {
  height: rem.convert(200px);
  position: relative;

  @include media-breakpoint-up(md) {
    &.with-watermark {
      margin-bottom: 2rem;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        right: 0;
        background-image: url('../images/logo-grey.png');
        background-position: center;
        background-size: cover;
        width: 30rem;
        height: 12rem;
      }
    }
  }

  .hide-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h1, h2 {
    text-transform: uppercase;
    color: $gray-300;
    font-family: $font-family-secondary;
    font-weight: 700;
    font-style: italic;
    margin: 0;
    padding: 0;
    font-size: rem.convert(50px);
    letter-spacing: rem.convert(10px);
    white-space: nowrap;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin-left: rem.convert(15px);
      letter-spacing: rem.convert(30px);
      font-size: rem.convert(100px);
    }

    @include media-breakpoint-up(lg) {
      margin-left: rem.convert(math.div(45px, 2));
      letter-spacing: rem.convert(45px);
      font-size: rem.convert(125px);
    }

    @include media-breakpoint-up(xl) {
      margin-left: rem.convert(25px);
      letter-spacing: rem.convert(50px);
      font-size: rem.convert(150px);
    }

    @include media-breakpoint-up(xxl) {
      margin-left: rem.convert(30px);
      letter-spacing: rem.convert(60px);
      font-size: rem.convert(190px);
    }
  }
}
