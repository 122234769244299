.about-page-intro {
  padding-bottom: rem.convert(130px);

  .italic-text {
    column-count: 1 !important;
    font-weight: $font-weight-light;
    font-family: $font-family-sans-serif;
  }
}

.about-team {
  background-color: $gray-500;
  padding-top: 1px;
  padding-bottom: rem.convert(200px);
  position: relative;
  z-index: 1;

  @include media-breakpoint-up(md) {
    &::after {
      content: '';
      position: absolute;
      height: 12rem;
      width: 30rem;
      right: 0;
      top: 328px;
      background-image: url('../images/logo-grey-400.png');
      background-size: cover;
      background-position: center right;
      z-index: -1;
    }
  }


  .page-header {
    margin-bottom: rem.convert(80px);

    h2 {
      color: $gray-400;
    }
  }

  .vertical-divider {
    background-color: $blue-800;
  }

  .team {
    max-width: rem.convert(886px);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0 auto rem.convert(8px);

    h3 {
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      flex: 0;
      margin: 0 rem.convert(30px) 0 0;
      font-weight: $font-weight-light;
      font-style: italic;
      text-transform: uppercase;
      font-size: rem.convert(30px);
    }
  }

  .members {
    flex: 1;
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    gap: rem.convert(10px) rem.convert(10px);
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(3, 1fr);
    }

    a {
      display: block;
      position: relative;
      color: $white;

      &:hover {
        h4 {
          opacity: 1;
        }
      }
    }

    img {
      display: block;
      width: 100%;
    }

    h4 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: rem.convert(20px) rem.convert(10px);
      font-size: rem.convert(20px);
      font-style: italic;
      text-transform: uppercase;
      background-image: linear-gradient(to bottom, transparent, $black);
      margin: 0;
      opacity: 0;
      transition: opacity 200ms ease-out;
    }
  }
}
