.contact {
  .italic-text {
    font-weight: $font-weight-light;
  }

  .input-col {
    margin-bottom: rem.convert(30px);
  }

  .form-control {
    background-color: $body-bg;
    border-color: transparent;
    border-bottom-color: $gray-700;
    color: $body-color;

    &:focus {
      border-bottom-color: $input-focus-border-color;
    }
  }

  textarea.form-control {
    min-height: 0;
  }

  .col-form-label {
    font-style: rem.convert(19px);
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    color: $gray-600;
  }

  .grow-wrap {
    display: grid;

    &::after {
      content: attr(data-replicated-value) ' ';
      white-space: pre-wrap;
      visibility: hidden;
      border-bottom: 1px solid transparent;
      padding: $input-padding-y $input-padding-x;
    }

    textarea {
      resize: none;
      overflow: hidden;
    }

    textarea,
    &::after {
      grid-area: 1 / 1 / 2 / 2;
    }
  }

  .submit-wrap {
    margin-bottom: rem.convert(200px);
  }

  .map {
    min-height: rem.convert(440px);
    display: block;
    position: relative;
  }
}
