.home-hero {
  .swiper-slide {
    position: relative;

    img {
      display: block;
      width: 100%;
      height: rem.convert(880px);
      object-fit: cover;
    }

    .slider-link {
      position: absolute;
      top: 50%;
      background: rgba($blue-800, 0.9);
      color: $white;
      text-align: center;
      text-transform: uppercase;
      font-weight: 300;
      font-style: italic;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: rem.convert(28px) rem.convert(35px);
      text-decoration: none;
      font-size: rem.convert(22px);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: none;
        transition: box-shadow 250ms ease-out;
      }

      &:hover {
        font-weight: 500;

        &::before {
          box-shadow: 0 0 40px rgba($blue-800, 0.89);
        }
      }
    }

    .slider-title {
      display: block;
      color: $blue;
      white-space: nowrap;
      width: 100%;
      font-family: $font-family-secondary;
      margin-bottom: 0;
      letter-spacing: 1px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      font-size: 1.5rem;
      padding: 1.5rem 1rem;

      @include media-breakpoint-up(md) {
        font-size: rem.convert(40px);
        padding: rem.convert(38px) rem.convert(25px) rem.convert(38px) 0;
        height: rem.convert(124px);
      }

      &:hover {
        color: $link-hover-color;
      }
    }
  }

  .navigation {
    border-left: 1px solid $blue-900;
    background-color: $body-bg;
    height: rem.convert(124px);
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(md) {
      margin-top: rem.convert(-124px);
    }
  }

  .navigation-btn {
    border: none;
    padding: 0 rem.convert(10px);
    background: transparent none;
    font-size: rem.convert(44px);
    color: $blue-400;

    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }

    .icon {
      display: block;
    }
  }

  .page-counter {
    color: $blue;
    display: flex;
    font-size: rem.convert(40px);
    align-items: center;

    .separator {
      width: rem.convert(100px);
      height: 1px;
      background-color: $blue-800;
      margin: 0 rem.convert(30px);
    }
  }
}
