// Bootstrap functions
@import 'bootstrap/scss/functions';

// Colors
$gray-200: #ebebeb;
$gray-300: #e6e6e6;
$gray-400: #c0c0c0;
$gray-500: #d4d4d4;
$gray-600: #9a9a9a;
$gray-700: #7c7c7c;
$gray-800: #303030;
$blue-400: #11a2cd;
$blue: #0f7a9a;
$blue-700: #194551;
$blue-800: #093341;
$blue-900: #06232c;

// Bootstrap variables and mixins
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Custom variables
// Configuration
$enable-caret: false;
$enable-rounded: false;

// Typography
$font-family-sans-serif: Lato, sans-serif;
$font-family-secondary: Roboto, sans-serif;

// Body options
$body-bg: $gray-200;
$body-color: $gray-800;

// Base font size
$font-size-root: 15px;

// Navbar
$navbar-padding-y: 2rem;
$navbar-nav-link-padding-x: 1rem;

// Forms
$input-color: $white;
$input-bg: $blue-900;
$input-border-color: $blue-900;
$input-focus-bg: $input-bg;
$input-focus-border-color: $input-focus-bg;
$input-focus-color: $input-color;

// Bootstrap components
@import 'bootstrap/scss/root';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/helpers/visually-hidden';
@import 'bootstrap/scss/helpers/ratio';
