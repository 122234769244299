.project-gallery {
  .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: $white;
    z-index: 9;
    background: transparent none;
    font-size: 2rem;
    line-height: 1em;
  }
  .modal-dialog {
    max-width: 100%;
    height: calc(100% - #{$modal-dialog-margin * 2});

    @include media-breakpoint-up(sm) {
      height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});
    }
  }

  .modal-content {
    height: 100%;
    background-color: transparent;
    border: none;
  }

  .swiper-container {
    height: 100%;
    width: 100%;
  }

  .swiper-slide {
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    $size: 50px;
    width: $size;
    height: $size;
    position: absolute;
    top: 50%;
    margin-top: math.div($size, -2);
    border-top: 4px solid $white;
    border-right: 4px solid $white;
    border-radius: 4px;
    cursor: pointer;
    z-index: 1;
    filter: drop-shadow(0 0 5px rgba($black, 0.5));
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .swiper-button-next {
    transform: rotate(45deg);
    right: 0;
    margin-right: $modal-dialog-margin * 2;

    @include media-breakpoint-up(sm) {
      margin-right: $modal-dialog-margin * 2;
    }
  }

  .swiper-button-prev {
    transform: rotate(225deg);
    margin-left: $modal-dialog-margin * 2;

    @include media-breakpoint-up(sm) {
      margin-left: $modal-dialog-margin * 2;
    }
  }
}
