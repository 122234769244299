.footer {
  background-color: $blue-800;
  padding: rem.convert(135px) 0 rem.convert(90px);
  color: $white;

  .main-row {
    align-items: center;
  }

  .contact-form {
    .col-12 {
      margin-top: 1rem;
    }

    textarea {
      height: rem.convert(115px);
    }

    .btn {
      color: $blue-400;
      background-color: $blue-800;
      border-color: $blue-800;
      text-transform: uppercase;
      margin-left: $btn-padding-x * -1;
    }
  }
}
